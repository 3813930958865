<template>
    <div v-if="response">
        <v-row no-gutters class="d-flex flex-grow-1 chat-container">
            <v-col class="d-flex" style="height: 100%" lg="4">
                <v-card style="overflow-y: auto;" class="pa-6 flex-grow-1 ma-4" outlined>
                    <!-- TODO add here avatar component with statuses-->
                    <div class="d-flex mb-8">
                        <div>
                            <v-tooltip :color="badgeColor" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs"
                                        v-on="on">
                                        <v-badge 
                                            avatar
                                            overlap
                                            bordered
                                            left
                                            :color="badgeColor"
                                            :value="!!respondentStatus"
                                            
                                        >
                                            <v-avatar color="grey lighten-3" size="50">
                                            <v-icon v-if="!response.avatarUrl" color="grey">mdi-account</v-icon>
                                            <img v-else :src="response.avatarUrl"/>
                                            </v-avatar>
                                        </v-badge>
                                    </div>
                                </template>
                                <span>{{respondentStatus}}</span>
                            </v-tooltip>
                        </div>
                        <div class="px-2">
                            <div class="mb-1">{{ identification }}</div>
                            <div class="text-caption">{{$date(response.createdAt.toDate()).format('MMM D, YYYY, h:mm A')}} (Created) • {{ $date(response.updatedAt.toDate()).fromNow() }} (Updated) </div>
                        </div>
                    </div>
                    <div class="text-subtitle-1 grey--text text--darken-1 mb-4">Info Tags</div>
                    <div class="d-flex flex-wrap">
                        <info-tag v-for="tag in infoTags" :key="tag[0]" :label="tag[0]" :value="tag[1]"></info-tag>
                    </div>
                    <div v-if="response.metadata">
                        <div class="text-subtitle-1 grey--text text--darken-1 text-subtitle-1 mb-4">Metadata</div>
                        <JsonViewer 
                            :value="response.metadata"
                            copyable
                            theme="json-view-theme"
                            :expand-depth=1
                        >
                        </JsonViewer>
                    </div>
                </v-card>
            </v-col>
            
            <v-col lg="8" style="overflow-y: auto; height: 100%;" class="pt-4 px-10 d-flex flex-column flex-grow-1">
                <v-card elevation="0" color="success lighten-5" class="pa-3 mb-4 d-flex align-center" v-if="response && response.submitted">
                    <v-icon color="success" left>mdi-check</v-icon> <span class="success--text text--darken-1">The response has been submitted</span>
                </v-card>
                <v-card v-else class="pa-3 mb-4" outlined>
                    <div class="d-flex align-center mb-3">
                        <div class="text-body-2" style="white-space: nowrap;">Complete response with the link</div>
                        <v-card v-if="accessToken" elevation="0" color="primary lighten-5" class="pa-2 link-container mx-2">
                            <div class="link text-body-2 primary--text text--darken-1">{{ resumeLink }}</div>
                        </v-card>
                        <v-btn v-if="accessToken" color="primary" elevation="0" @click="handleCopyLink">
                            <v-icon left>mdi-link</v-icon>
                            Copy
                        </v-btn>
                        <template v-else>
                            <v-spacer></v-spacer>
                            <v-btn  :loading="generatingAccessToken" color="primary" elevation="0" @click="generateAccessLink">
                                <v-icon left>mdi-lock</v-icon>
                                Generate access link
                            </v-btn>
                        </template>
                    </div>
                    <div class="d-flex align-center">
                        <div class="d-flex align-center mr-3">
                            <div class="ring-container mr-2">
                                <div class="circle"></div>
                                <div class="ringring"></div>
                            </div>
                            <span class="text-caption success--text text--darken-1">Live</span>
                        </div>
                        <v-progress-linear rounded height="4" :value="response.answersCompletion"></v-progress-linear>
                        <span class="text-caption text-right ml-3" style="white-space: nowrap; min-width: 100px;">
                            {{response.answersCompletion||0}}% completed
                        </span>
                    </div>
                </v-card>
                <div
                    v-for="(coveredStep, index) in coveredSteps"
                    :key="coveredStep.id ||`step-${index}`"
                    class="mb-8"
                >
                    <response-details-question :index="index" :question="coveredStep.question" :loopIndex="getLoopIndexFromQuestion(coveredStep.question)"></response-details-question>
                    <div v-if="!['description-slide', 'file-upload'].includes(coveredStep.question.type)" class="mr-5">
                        <template v-if="coveredStep.answer">
                            <audio-item disableLike :avatarUrl="avatarUrl" v-if="audioType.includes(coveredStep.question.type)" :answer="{
                                ...coveredStep.answer, 
                                position: index+1,
                                // reuse the transcript from probes
                                transcript: coveredStep.probes && coveredStep.probes.length && coveredStep.probes[0].transcription && coveredStep.probes[0].transcription.text || coveredStep.answer.transcript,
                            }"></audio-item>
                            <regular-item disableLike :avatarUrl="avatarUrl" v-else :answer="{...coveredStep.answer, value: {value: coveredStep.answer.value, type: coveredStep.answer.type},  position: index+1}"/>
                        </template>
                        <results-no-answer v-else></results-no-answer>
                    </div>
                    <!-- Files -->
                    <div v-else-if="coveredStep.question.type==='file-upload'">
                        <div v-if="coveredStep.answer" class="mr-5">
                            <results-file-attachment :avatarUrl="avatarUrl"  :answer="{value: coveredStep.answer}" :question="coveredStep.question"></results-file-attachment>
                        </div>
                    </div>

                    <!-- Probes -->
                    <div
                        v-for="(probe, index) in coveredStep.probes"
                        :key="probe.id"
                        class="mb-2"
                    >
                        <div v-if="index" class="mr-5">
                            <template v-if="probe.answer">
                                <audio-item disableLike :avatarUrl="avatarUrl" :answer="{
                                        file_url: probe.answer.url || null,
                                        file_path: probe.answer.path || null,
                                        transcript: probe.transcription && probe.transcription.text || null,
                                        status: 'complete'
                                    }"></audio-item>
                            </template>
                            <results-no-answer v-else></results-no-answer>
                        </div>
                        <probing-question v-if="!probe.sufficient || probe.failed" :failed="probe.failed" :followup="probe.followup" :question="coveredStep.question" ></probing-question>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapMutations} from "vuex";
import { VOICE_QUESTION, VOICE_RESPONSE } from '../../../../configs/questionTypes';
import { db } from '@/firebase'
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import ListItem from '../../../../components/answer/ListItem.vue';
import JsonViewer from 'vue-json-viewer'
import InfoTag from './InfoTag.vue';
import AudioItem from '../../../../components/answer/audio/ResultsAudioItem.vue';
import RegularItem from '../../../../components/answer/regular/ResultsRegularItem.vue';
import ResultsNoAnswer from '../../../../components/answer/regular/ResultsNoAnswer.vue'
import ResponseSkeletonLoader from "./ResponseSkeletonLoader.vue";
import ProbingQuestion from './ProbingQuestion.vue';
import ResponseDetailsQuestion from './ResponseDetailsQuestion.vue';
import ResultsFileAttachment from '../../../../components/answer/regular/ResultsFileAttachment.vue';
import PartialResponsesApi from '@/api/PartialResponsesApi'

const LEAD_DATA= [
    {
        title: 'Name',
        key: 'name'
    },
    {
        title: 'Email',
        key: 'email'
    },
    {
        title: 'Phone',
        key: 'phone_number'
    },
    {
        title: 'Location',
        key: 'location'
    },
]
export default {
    name: "PartialResponseDetails",
    data() {
        return {
            responseId: this.$route.params.responseId,
            leadData: LEAD_DATA,
            downloadingAudio: false,
            audioType: [VOICE_QUESTION, VOICE_RESPONSE],
            response: null,
            generatingAccessToken: false,
        };
    },
    inject: ['survey'],
    components: { 
        ContainerCard, ListItem, JsonViewer, InfoTag, 
        ResponseDetailsQuestion,
        AudioItem, RegularItem, ResultsNoAnswer, ResponseSkeletonLoader,
        ProbingQuestion,
        ResultsFileAttachment
     },

    firestore() {
        return{
            response: db.collection('partial-responses').doc(this.$route.params.responseId),
        }
    },

    computed:{
        avatarUrl(){
            return this.response?.avatarUrl || null
        },
        identification(){
            if(this.response.metadata){
                if(this.response.metadata.email){
                    return this.response.metadata.email
                }
                if(this.response.phone){
                    return this.response.metadata.phone
                }
                if(this.response.name){
                    return this.response.metadata.name
                }
            }
            return this.response.id
        },
        coveredSteps() {
            if(this.response && this.response.steps){
                return this.response.steps.reduce((previousSteps, step) => {
                    const answer = this.response.answers[step.id]?.answer
                    const probes = this.response.dialogues[step.id]
                    if(answer){
                        previousSteps.push({
                        question: step,
                        answer: answer? {
                            ...answer,
                            transcript: answer.value?`${answer.value}`: null,
                            file_url: answer.url
                        }: null,
                        probes
                    })
                    }

                    return previousSteps
                }, [])
            }
            return []
        },
        infoTags(){
            let tags = {}
            if(this.response){
                if(this.response.id){
                    tags.id = `res-${this.response.id}`
                }
                if(this.response.metadata && typeof this.response.metadata === 'object'){
                    // prioritize email and name keys
                    if(this.response.metadata.email){
                        tags = {...tags, email: this.response.metadata.email}
                    }
                    if(this.response.metadata.phoneNumber){
                        tags = {...tags, phone: this.response.metadata.phoneNumber}
                    }
                    if(this.response.metadata.name){
                        tags = {...tags, name: this.response.metadata.name}
                    }
                }
            }
            return Object.entries(tags)
        },
        result(){
            return this.response
        },
        accessToken(){
            return this.response?.accessToken || null
        },
        resumeLink(){
            const params = new URLSearchParams();

            // Append responseId
            params.append('v_pr', this.accessToken);

            
            return `${window.location.origin}/to/${this.survey.hash}?${params.toString()}`;
        },
        respondentStatus(){
            if(this.response && this.response.updatedAt){
                // if updated within 5 minutes consider active
                if(this.response.updatedAt.toDate().getTime() > Date.now() - 5 * 60 * 1000){
                    return 'Active'
                }
                // if updated within 1 hour consider away
                if(this.response.updatedAt.toDate().getTime() > Date.now() - 60 * 60 * 1000){
                    return 'Away'
                }

                // if last update was more then an 1 hour consider offline
                return 'Offline'
            }
            return null
        },
        badgeColor(){
            if(this.respondentStatus){
                if(this.respondentStatus === 'Active'){
                    return 'success'
                }
                if(this.respondentStatus === 'Away'){
                    return 'warning'
                }

                return 'grey lighten-1'
            }
            return null
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        getLoopIndexFromQuestion(question){
            try {
                if(question && question.id && question.id.includes('_loop_')){
                    // extract index and convert to number
                    return parseInt(question.id.split('_loop_')[1])
                }
            } catch (error) {
                return 0
            }
            return 0
        },
        async handleCopyLink(){
            try {
                await navigator.clipboard.writeText(this.resumeLink);
                this.showSnackBar({
                    text: 'Link copied to clipboard',
                    timeout: 3000,
                    color: 'success',
                    right: true,
                    top: true,
                })
            } catch (error) {
                this.showSnackBar({
                    text: 'Failed to copy link to clipboard',
                    timeout: 3000,
                    color: 'error',
                    right: true,
                    top: true,
                })
            }
        },
        async generateAccessLink(){
            try {
                this.generatingAccessToken = true
                const res =  await PartialResponsesApi.generatePartialResponseAccessToken(this.survey.id,this.responseId)
                if(res.data && res.data.token){
                    this.showSnackBar({
                        text: 'Access link generated',
                        timeout: 3000,
                        color: 'success',
                        right: true,
                        top: true,
                    })
                }
            } catch (error) {
                console.error(error);
                this.showSnackBar({
                    text: 'Failed to generate access link',
                    timeout: 3000,
                    color: 'error',
                    right: true,
                    top: true,
                })
            }finally{
                this.generatingAccessToken = false
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
.link-container{
    display: flex;
    flex-grow: 1;
    min-width: 0;

    .link{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.result-id{
    border-radius: 4px;
    padding: 4px 12px;
    background-color: var(--v-primary-lighten5);
}
.answers-container{
    .header {
        font-size: 10px;
    }
    .transcript{
        font-size: 14px;
    }
    font-size: 16px;
}
.chat-container{
    height: 100vh;
    max-height: calc(100vh - 116px);
}
.ring-container {
  position: relative;
  width: 6px; /* Setting a defined width and height for better visual. */
  height: 6px;
}

.circle {
  width: 6px;
  height: 6px;
  background-color: var(--v-success-base);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ringring {
  border: 2px solid  var(--v-success-base);
  border-radius: 50%; /* Setting to 50% for a perfect circle. */
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite; 
  opacity: 0.0;
}

@keyframes pulsate {
  0% { transform: translate(-50%, -50%) scale(0.1); opacity: 0.0; }
  50% { transform: translate(-50%, -50%) scale(0.6); opacity: 1.0; }
  100% { transform: translate(-50%, -50%) scale(1.2); opacity: 0.0; }
}
</style>
<template>
    <div class="progress-container" :style="{ width: size + 'px', height: size + 'px' }">
      <svg :width="size" :height="size">
        <circle
          class="progress-ring__background"
          :stroke="backgroundColor"
          stroke-width="4"
          fill="transparent"
          :r="radius"
          :cx="center"
          :cy="center"
        ></circle>
        <circle
          class="progress-ring__circle"
          :stroke="progressColor"
          stroke-width="4"
          fill="transparent"
          :r="radius"
          :cx="center"
          :cy="center"
        ></circle>
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      progress: {
        type: Number,
        required: true,
        default: 0
      },
      size: {
        type: Number,
        default: 40
      },
      progressColor: {
        type: String,
        default: "#1976D2" // Default to Vuetify's primary blue color
      },
      backgroundColor: {
        type: String,
        default: "#E0E0E0" // Default to Vuetify's light grey color
      }
    },
    computed: {
      radius() {
        return (this.size / 2) - 4; // Subtracting the stroke width to fit the circle inside the SVG
      },
      center() {
        return this.size / 2;
      }
    },
    watch: {
      progress(newValue) {
        this.updateProgress(newValue);
      },
    },
    methods: {
      updateProgress(progressValue) {
        const circle = this.$el.querySelector(".progress-ring__circle");
        const circumference = this.radius * 2 * Math.PI;
  
        const offset = circumference - (progressValue / 100) * circumference;
        circle.style.strokeDashoffset = offset;
      },
    },
    mounted() {
      const circle = this.$el.querySelector(".progress-ring__circle");
      const circumference = this.radius * 2 * Math.PI;
  
      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      this.updateProgress(this.progress);
    },
  };
  </script>
  
  <style scoped>
  .progress-container {
    position: relative;
  }
  
  .progress-ring__background, .progress-ring__circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    stroke-linecap: round;
  }
  </style>
<template>
    <results-layout>
        <div v-if="authenticating">
            <v-overlay>
                <v-progress-circular
                    indeterminate
                    size="32"
                ></v-progress-circular>
            </v-overlay>
        </div>
        <template v-else>
            <partial-responses v-if="partialResponsesEnabled && fsUser"></partial-responses>
            <!-- Promo -->
            <v-container style="max-width: 1200px;" class="pt-8" :fluid="false" v-else>
                <v-row max-width="900">
                    <v-col>
                        <div class="text-h4 mb-8">Introducing Partial Responses!</div>
                        <div class="text-subtitle-1 mb-8">Capture your respondents answers every step of the way. Whether they have submitted or not, we’ve got the data saved securely.</div>
                        <div v-for="(feature, index) in prFeatures" :key="`pr-feature-${index}`" class="d-flex align-center mb-6">
                            <v-avatar color="primary lighten-5">
                                <v-icon color="primary darken-1">{{feature.icon}}</v-icon>
                            </v-avatar>
                            <div class="ml-2">{{feature.text}}</div>
                        </div>
                        <v-btn @click="handleLearnMore" class="mt-5" elevation="0" height="42" color="primary">Learn more about add-on</v-btn>
                    </v-col>
                    <v-col>
                        <v-img max-width="600" width="100%" src="https://cdn.voiceform.com/media/partial-responses-illustration.svg"  />
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </results-layout>
</template>

<script>
import ResultsLayout from '../../../../layouts/ResultsLayout.vue'
import FsApi from '@/api/FsApi';
import PartialResponses from './PartialResponses.vue';
import { auth } from '@/firebase'
import { mapGetters } from 'vuex';
import { Crisp } from 'crisp-sdk-web';

export default {
    name: 'PartialResponsesLayout',
    components: { ResultsLayout, PartialResponses },
    computed:{
        ...mapGetters({
            features: 'auth/features',
        }),

        partialResponsesEnabled(){
            return this.features && this.features.partial_responses || false
        },
        showExpiredProbingTrial(){
            return this.features && this.features.probing_trial_ended_at && !this.hasProbingFeature
        }
    },
    data() {
        return {
            authenticating: true,
            fsUser: null,
            prFeatures: [
                {
                    icon: 'mdi-cloud-check',
                    text: 'Never lose data again.'
                },
                {
                    icon: 'mdi-lightning-bolt',
                    text: 'Real-time saving of every form input.'
                },
                {
                    icon: 'mdi-file-restore',
                    text: 'Restore and resume responses at any point with a unique link.'
                },
                {
                    icon: 'mdi-security',
                    text: 'Enhanced security measures to ensure data privacy.'
                },
            ]
        }
    },

    async created() {
        if(this.partialResponsesEnabled){
            const fsRes = await FsApi.generateFsAuthToken()
            try {
                if(fsRes.data && fsRes.data.token){
                    await auth.signInWithCustomToken(fsRes.data.token);
                }
            } catch (error) {
                console.error("Error signing in with custom token:", error);
            }
            auth.onAuthStateChanged(user => {
                this.fsUser = user;
                this.authenticating = false;
            });
        }else{
            this.authenticating = false;
        }
    },
    methods: {
        handleLearnMore(){
            Crisp.chat.open();
            Crisp.message.send("text", "Hi, I would like to learn more about partial responses add-on.")
        }
    }
}
</script>

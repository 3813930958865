<template>
    <response-skeleton-loader v-if="[STATES.PENDING].includes(state)"></response-skeleton-loader>
    <div v-else>
        <v-navigation-drawer permanent left class="d-flex nav-drawer" clipped width="350" app>
            <div class="pt-12 pb-4 px-6">
                <div class="mt-6 d-flex align-center">
                    <div class="text-subtitle-1">Partial Responses</div>
                </div>
                <v-tooltip v-if="showAutosubmitSettings" max-width="250" right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-card v-bind="attrs" v-on="on" elevation="0" color="grey lighten-4" class="pa-3 py-2 mt-3">
                            <div class="pr-2 d-flex align-center">
                                <div class="text-caption">
                                    <b>Autosubmit</b>
                                    <v-chip v-if="autosubmitEnabled" color="success lighten-4" class="ml-1 success--text text--darken-4 " label small>Active</v-chip>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn small @click="autosubmitSettingsDialog=true" icon><v-icon small>mdi-cog</v-icon></v-btn>
                            </div>
                        </v-card>
                    </template>
                    <span class="text-caption">Our system will automatically review and submit your close-to-submission responses daily.</span>
                </v-tooltip>
                
            </div>
            <div class="px-6">
                <div class="d-flex">
                    <v-text-field
                        class="text-body-2"
                        v-model="search"
                        placeholder="Search by email"
                        hide-details
                        dense
                        outlined
                        v-on:keydown.enter="handleSearch"
                        :loading="[STATES.PENDING, STATES.VALIDATING].includes(this.state)"
                    >
                        <template v-slot:append>
                            <v-menu ref="filtersMenu" :close-on-content-click="false" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="mt-n2"
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-filter-variant</v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="pa-3" width="400">
                                    <!-- Completion Percentage -->
                                    <v-row class="align-center">
                                        <v-col sm="4" min-width="100">
                                            <div class="text-body-2 grey--text text--darken-1">Completion</div>
                                        </v-col>
                                        <v-col class="d-flex align-center"  sm="8">
                                            <v-slider
                                                v-model="completionValue"
                                                :max="100"
                                                :step="10"
                                                :min="0"
                                                :thumb-label="false"
                                                hide-details="auto"
                                                class="mr-4"
                                            ></v-slider>
                                            <div style="min-width: 100px;" class="text-right text-caption">{{completionValue}}% and more</div>
                                        </v-col>
                                    </v-row>
                                    <!-- <div  class="mb-3 d-flex align-center">
                                        <div style="min-width: 100px;" class="text-body-2 grey--text">Completion</div>
                                        <v-slider
                                            v-model="completionValue"
                                            :max="100"
                                            :step="5"
                                            :min="0"
                                            :thumb-label="false"
                                            hide-details="auto"
                                            class="mr-4"
                                        ></v-slider>
                                        <div class="text-right text-caption">{{completionValue}}% and more</div>
                                    </div> -->
    
                                    <!-- <div class="d-flex">
                                        <div style="min-width: 100px;" class="mb-2 text-body-2">Created at</div>
                                        <v-menu v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    outlined
                                                    v-model="startDate"
                                                    label="Start Date"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="startDate" @input="menuStartDate = false"></v-date-picker>
                                        </v-menu>
                                        
                                        <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    outlined
                                                    v-model="endDate"
                                                    label="End Date"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="endDate" @input="menuEndDate = false"></v-date-picker>
                                        </v-menu>
                                    </div> -->
                                    <div class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn @click="handleApplyFilters" elevation="0">Apply Filters</v-btn>
                                    </div>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-text-field>
                    <v-btn @click="handleSearch" class="ml-1" min-width="40" width="40" height="40" elevation="0">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </div>

                <div class="d-flex">
                    <v-chip @click:close="()=>removeFilter('completion')" close v-if="$route && $route.query && $route.query.completion" class="mr-2 mt-2" label small color="primary" >{{ $route.query.completion }}% and more</v-chip>
                    <v-chip @click:close="()=>removeFilter('q')" close v-if="$route && $route.query && $route.query.q" class="mr-2 mt-2" label small color="primary" >{{$route.query.q.length>10?`${$route.query.q.slice(0,10)}...`:$route.query.q}}</v-chip>
                </div>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="pt-4 px-6 pb-12 responders-list-container">
                <template v-if="responses && responses.length">
                    <partial-response-side-item
                        v-for="(response) in responses" 
                        :key="response.id" 
                        :response="response"
                        @delete="handleResultDelete(response)"
                        @copyLink="handleCopyLink(response)"
                        @click="handleResultSelect(response)"
                    ></partial-response-side-item>
                </template>
                <div v-if="showLoadMore" class="d-flex pa-6 full-width flex-grow-1 align-center justify-center" v-intersect="{
                    handler: onIntersect,
                    options: {
                        threshold: 0,
                        rootMargin: '0px 0px 400px 0px',
                    }
                }">
                    <v-btn :loading="loadingMore" :disabled="loadingMore" elevation="0" @click="loadMore">Show More</v-btn>
                </div>
            </div>
        </v-navigation-drawer>
        <div>
            <partial-response-details v-if="responses && $route.params.responseId" :key="`partial-response-${$route.params.responseId}`"></partial-response-details>
            <div v-else class="mt-5">
                <no-answers ></no-answers>
            </div>
        </div>
        <v-dialog width="400" v-model="autosubmitSettingsDialog">
            <v-card class="pa-4">
                <div class="text-subtitle-1">Autosubmit Settings</div>
                <v-divider class="my-3"></v-divider>
                <v-card elevation="0" :color="autosubmitEnabled? 'primary lighten-5':'grey lighten-4'" class="d-flex align-center pa-2 mb-3">
                    <div class="">Enable Autosubmit</div>
                    <v-spacer></v-spacer>
                    <v-switch hide-details="auto" class="pa-0 ma-0" v-model="autosubmitEnabled"></v-switch>
                </v-card>
                <div class="d-flex align-center mb-3">
                    <div>
                        <v-tooltip max-width="250" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small left>mdi-information</v-icon>
                            </template>
                            <span class="text-caption">The completion percentage required to automatically submit response.</span>
                        </v-tooltip>
                        Completion
                    </div>
                    <v-spacer></v-spacer>
                    <div style="max-width: 150px">
                        <v-select
                            width="50"
                            dense
                            outlined
                            class="text-caption"
                            hide-details="auto"
                            :items="[
                                {text: '>= 10%', value: 10},
                                {text: '>= 20%', value: 20},
                                {text: '>= 30%', value: 30},
                                {text: '>= 40%', value: 40},
                                {text: '>= 50%', value: 50},
                                {text: '>= 60%', value: 60},
                                {text: '>= 70%', value: 70},
                                {text: '>= 80%', value: 80},
                                {text: '>= 90%', value: 90},
                                {text: '>= 100%', value: 100},
                            ]"
                            v-model="autosubmitCompletion"
                        ></v-select>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="d-flex align-center justify-space-between">
                        <div>
                            <v-tooltip max-width="250" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small left>mdi-information</v-icon>
                                </template>
                                <span class="text-caption">Required time of inactivity before response is automatically submitted.</span>
                            </v-tooltip>
                            Inactivity threshold
                        </div>
                        <span class="text-caption primary--text"><b>{{ autosubmitInactivityTime }} hour(s)</b></span>
                    </div>
                    <v-spacer></v-spacer>
                    <v-slider
                        v-model="autosubmitInactivityTime"
                        :max="168"
                        :min="12"
                        ticks="always"
                        :step="12"
                    ></v-slider>
                </div>

                <div>
                    <v-btn elevation="0" color="primary" @click="saveAutosubmitSettings">
                        Save
                    </v-btn>
                    <v-btn text @click="autosubmitSettingsDialog=false">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
        <!-- <v-dialog
                v-model="deleteDialog"
                width="500"
                @click:outside="closeDeleteDialog"
            >
            <v-card class="remove-dialog pt-4 pb-3 px-6">
                <div class="text-h6">
                    Delete Response
                </div>
                <p>Are you sure you want to delete <b>{{deletingResult && (getNameFromResult(deletingResult) || `Responder #${deletingResult.id}`)}}</b> response? Once deleted, you will not be able to recover this response or its contents.</p>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="closeDeleteDialog"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    :elevation="0"
                    @click="deleteResponse"
                    :loading="deleteLoading"
                    :disabled="deleteLoading"
                >
                    Yes, Delete it
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
</template>

<script>
import ResultsLayout from '../../../../layouts/ResultsLayout.vue'
import QuestionDetails from './QuestionDetails.vue';
import NoQuestions from './NoQuestions.vue'
import ResponseSideItem from './ResponseSideItem.vue';
import NoAnswers from './NoAnswers.vue';
import ResponseDetails from './ResponseDetails.vue';
import { partialResponsesFetcher, auth } from '@/firebase'
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import useSwrvState from '@/utils/useSwrvState'
import PartialResponseSideItem from './PartialResponseSideItem.vue';
import PartialResponseDetails from './PartialResponseDetails.vue';
import ResponseSkeletonLoader from "./ResponseSkeletonLoader.vue";
import FsApi from '@/api/FsApi';
import { inject } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import BuilderApi from '@/api/BuilderApi';

export default {
    data(){
        return {
            limit: 5,
            loadingMore: false,
            nextPaginationSize: 0,
            lastVisible: null,
            search: '',
            completionValue: 0,
            startDate: null,
            endDate: null,
            openFilterMenu: false,
            savingAutosubmitSettings: false,
            autosubmitSettingsDialog: false,
            autosubmitCompletion: this.survey.autosubmit_partial_responses_preferences?.autosubmit_after_percentage || 70,
            autosubmitInactivityTime: this.survey.autosubmit_partial_responses_preferences?.autosubmit_after_hours || 24,
            autosubmitEnabled: this.survey.autosubmit_partial_responses || false,
        }
    },
    components: { ResultsLayout, QuestionDetails, NoQuestions, ResponseSideItem, NoAnswers, ResponseDetails, PartialResponseSideItem, PartialResponseDetails, ResponseSkeletonLoader},
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),
        showLoadMore(){
            return ![this.STATES.PENDING].includes(this.state) && this.responses && this.responses.length && this.responses.length>=this.limit && (this.lastVisible===null || this.nextPaginationSize > this.limit)
        },

        showAutosubmitSettings(){
            return !!this.features && this.features.partial_responses_auto_submit
        },

        fetchUrl(){
            const { surveyId } = this.$route.params;
            const params = new URLSearchParams();

            params.set('completion', this.$route.query.completion || '0');
            params.set('teamId', this.survey?.team_id || '');
            params.set('limit', this.limit+1);

            if(this.$route.query.q){
                params.set('q', this.$route.query.q);
            }

            return `partial-res/${surveyId}/?${params.toString()}`;
        },
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async loadMore() {
            this.loadingMore = true;
            if(!this.lastVisible) {
                this.lastVisible = this.responses[this.responses.length - 1];
            }

            let next = await partialResponsesFetcher(this.fetchUrl, this.lastVisible.createdAt);

            this.responses = [...this.responses, ...next];

            this.lastVisible = next[next.length - 1];

            this.nextPaginationSize = next && next.length || 0;

            this.loadingMore = false;
        },
        onIntersect(entries) {
            if(entries[0].isIntersecting){
                this.loadMore()
            }
        },
        handleRefreshResponses(){
            this.refetchResponses()
            this.lastVisible=null
            this.loadingMore=false
        },
        handleApplyFilters(){
            if(this.$refs?.filtersMenu){
                this.$refs.filtersMenu.isActive = false
            }
            this.$router.push({
                query: {
                    ...this.$route.query,
                    completion: this.completionValue,
                }
            })
        },
        removeFilter(filterKey){
            const query = {...this.$route.query}
            delete query[filterKey]
            this.$router.push({
                query
            })
        },
        handleSearch(){
            this.$router.push({
                query: {
                    ...this.$route.query,
                    q: this.search,
                }
            })
            this.handleRefreshResponses();
        },
        async saveAutosubmitSettings(){
            try {
                this.savingAutosubmitSettings = true
                await BuilderApi.patchForm(this.survey.id, {
                        autosubmit_partial_responses: this.autosubmitEnabled,
                        autosubmit_partial_responses_preferences: {
                            autosubmit_after_percentage: this.autosubmitCompletion,
                            autosubmit_after_hours: this.autosubmitInactivityTime
                        }
                    })
                await this.refreshSurvey()
                this.showSnackBar({
                    text: "Autosubmit settings saved successfully.",
                    color: 'success', 
                    timeout: 4000
                })
                this.autosubmitSettingsDialog = false
            } catch (error) {
                this.showSnackBar({
                    text: "Whoops, looks like something went wrong, and we cannot save autosubmit settings. Please try again later.",
                    color: 'error', 
                    timeout: 4000
                })
            }finally{
                this.savingAutosubmitSettings = false
            }
        },
    },
    watch: {
        '$route.query': {
            handler(){
                this.lastVisible=null
                this.loadingMore=false
            },
            deep: true
        },
        'responses': {
            immediate: true,
            handler(){
              if(this.$route.name === 'dashboard.voiceform.results.partial-responses' && this.responses && this.responses.length && this.$route.params && this.$route.params.surveyId && !this.$route.params.responseId){
                this.$router.replace({
                    name: 'dashboard.voiceform.results.partial-responses', 
                    params: { surveyId: this.$route.params.surveyId, responseId: this.responses[0].id },
                    query: { ...this.$route.query }
                })
              }
            }
        }
    },
    async mounted(){
        const fsRes = await FsApi.generateFsAuthToken()
        try {
            if(fsRes.data && fsRes.data.token){
                await auth.signInWithCustomToken(fsRes.data.token);
            }
        } catch (error) {
            console.error("Error signing in with custom token:", error);
        }
    },
    created() {
        auth.onAuthStateChanged(user => {
            this.user = user;
        });
    },
    inject: ['survey', 'refreshSurvey'],
    setup() {
        const route = useRoute();

        const surveyRef = inject('survey');

        const constructURL = () => {
            const { surveyId } = route.params;
            const params = new URLSearchParams();

            params.set('completion', route.query.completion || '0');
            params.set('teamId', surveyRef?.value?.team_id || '');

            if(route.query.q){
                params.set('q', route.query.q);
            }

            return `partial-res/${surveyId}/?${params.toString()}`;
        }

        const { data, error, isValidating, mutate } = useSWRV(
            constructURL,
            partialResponsesFetcher,
            {
                revalidateOnFocus: false,
            }
        );

        const { state, STATES } = useSwrvState(data, error, isValidating);

        return {
            state, STATES,
            responses: data,
            error,
            isValidating,
            refetchResponses: mutate
        }
    },
}
</script>

<style lang="scss" scoped>
.nav-drawer::v-deep .v-navigation-drawer__content{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.responders-list-container{
    overflow-y: auto;
    width: 100%;
    flex: 1;
}
</style>
<template>
    <v-slide-x-transition>
        <v-card
            class="pa-4 mb-6"
            outlined 
            :to="{
                name: 'dashboard.voiceform.results.partial-responses',
                params: { surveyId: $route.params.surveyId, responseId: response.id },
                query: { ...$route.query }
            }"
            :color="active? 'primary darken-4': ''"
            :dark="active"
            :id="`responder-${response.id}`"
        >
            <!-- <v-hover  v-slot="{ hover }"> -->
                <div class="d-flex flex-grow-1">
                    <div>
                        <!-- use icon by default or avatarUrl from response -->
                        <v-avatar color="grey lighten-3" size="35">
                            <v-icon v-if="!response.avatarUrl" :color="active?'primary':'grey'">mdi-account</v-icon>
                            <img v-else :src="response.avatarUrl"/>
                        </v-avatar>
                    </div>
                    <div class="text-caption flex-grow-1 px-4">
                        <div class="elipsis-text">{{identification}}</div>
                        <div :class="active?'primary--text text--lighten-5':'grey--text text--darken-1'">
                            {{$date(response.createdAt.toDate()).format('MMM D, YYYY h:mm A')}}</div>
                    </div>
                    <circle-progress-bar :progress="response.answersCompletion" :size="35" :backgroundColor="$vuetify.theme.themes.light.primary.lighten4" :progressColor="$vuetify.theme.themes.light.primary.base" />
                    <!-- TODO: add delete and copy link functionality -->
                    <!-- <div v-if="hover" class="overlay" :style="{background: `linear-gradient(to left, ${active? $vuetify.theme.themes.light.primary.darken4: 'white' } 0%,${active? $vuetify.theme.themes.light.primary.darken4: 'white' } 50%,rgba(255,255,255,0) 100%)`}"></div> -->
                    <!-- <v-menu offset-x>
                        <template v-slot:activator="{ on, attrs }">
                                <v-btn plain class="mx-n4" v-bind="attrs" @click.prevent v-on="on" icon>
                                    <v-icon v-if="hover || (attrs &&  attrs['aria-expanded']==='true')">mdi-dots-vertical</v-icon>
                                </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="$emit('copyLink', response)">
                                <v-list-item-title>Copy link</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$emit('delete', response)">
                                <v-list-item-title>Delete response</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu> -->
                </div>
            <!-- </v-hover> -->
        </v-card>
    </v-slide-x-transition>
</template>

<script>
import CircleProgressBar from '../../../../components/dashboard/CircleProgressBar.vue'
export default {
  components: { CircleProgressBar },
    props: {
        response: {
            required: true
        }
    },
    computed: {
        identification(){
            if(this.response.metadata){
                if(this.response.metadata.email){
                    return this.response.metadata.email
                }
                if(this.response.phone){
                    return this.response.metadata.phone
                }
                if(this.response.name){
                    return this.response.metadata.name
                }
            }

            return this.response.id
        },
        responderName() {
            return this.response && this.response.information_data && (this.response.information_data.name || this.response.information_data.first_name || this.response.information_data.last_name) || null
        },
        responderEmail(){
            return this.response && this.response.information_data && this.response.information_data.email || null
        },
        active(){
            return this.$route.params.responseId == this.response.id
        }
    },
    methods:{
        getInfoFromAnswer(type){
            const answer = this.response.answers.find(answer => answer.value && answer.value.type == type);
            return answer && answer.value && answer.value.value || null;
        }
    }
}
</script>

<style lang="scss" scoped>
.question-type-badge{
    width: 60px;
    height: 24px;
    min-width: 60px;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
}
.elipsis-text{
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.overlay{
    width: 60px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: inherit;
    // white gradient to transparent. form right to left
    background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
}
</style>